<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Atividades</li>
    </ol>
  </nav>

  <div class="container">
    <div class="row">
      <div class="col-sm">
        <router-link to="/setting/list" class="btn btn-primary btn-lg w-100 p-3">
          Dados do clube
        </router-link>
      </div>
      <div class="col-sm">
        <router-link to="/users" class="btn btn-primary btn-lg w-100 p-3">
          Usuários
        </router-link>
      </div>
      <div class="col-sm">
        <router-link to="/setting/list" class="btn btn-primary btn-lg w-100 p-3">
          Perfil de acessos
        </router-link>
      </div>
      <div class="col-sm">
        <button class="btn btn-primary btn-lg w-100 p-3">Sincronizar Associados</button>
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  
}
</script>
